var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.key + 'item',staticClass:"v-task-comment-item",class:{ mobile: _vm.isMobile },attrs:{"id":'task-comment-' + _vm.comment.id}},[_c('v-avatar',{staticClass:"avatar",attrs:{"src":_vm.comment.user_info.avatar},on:{"click":function($event){return _vm.$router.push(_vm.$paths.users + _vm.comment.user_info.id)}}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[_c('span',{on:{"click":function($event){return _vm.$router.push(_vm.$paths.users + _vm.comment.user_info.id)}}},[_vm._v(" "+_vm._s(_vm.comment.user_info.nickname || _vm.$t("anonymous"))+" ")]),_c('div',{staticClass:"time",on:{"click":_vm.onTimeClick}},[_vm._v(" "+_vm._s(_vm._f("timeFormat")(_vm.comment.create_time))+" ")])]),_c('i',{directives:[{name:"visible",rawName:"v-visible",value:(
        _vm.allow('comment', 'delete_others') ||
          (_vm.allow('comment', 'delete') && _vm.comment.user_info.id === _vm.user.id)
      ),expression:"\n        allow('comment', 'delete_others') ||\n          (allow('comment', 'delete') && comment.user_info.id === user.id)\n      "}],staticClass:"delete iconfont icon-delete",on:{"click":_vm.onDelete}}),_c('div',{staticClass:"content ql-editor",domProps:{"innerHTML":_vm._s(_vm.comment.content)}}),_c('div',{staticClass:"actives"},[_c('div',{staticClass:"center"},[_c('v-task-comment-modal',{attrs:{"taskId":_vm.taskId,"task":_vm.task,"commentId":_vm.comment.id,"nickname":_vm.comment.user_info.nickname},on:{"change":_vm.onReply}},[_c('div',{staticClass:"reply"})]),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.comment.reply_count || ""))]),_c('div',{staticClass:"like",class:{ active: _vm.comment.is_like },on:{"click":_vm.onLike}}),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.comment.like_count || ""))])],1),(_vm.task.max_reward_number && _vm.task.status === 'processing')?_c('div',{staticClass:"coin"},[(
            (!_vm.comment.reward_info &&
              ((_vm.allow('reward', 'create') && _vm.task.user_id === _vm.user.id) ||
                _vm.allow('reward', 'other_create_reward'))) ||
              (_vm.isChange &&
                ((_vm.allow('reward', 'update') && _vm.task.user_id === _vm.user.id) ||
                  _vm.allow('reward', 'other_update_reward')))
          )?[_c('img',{staticClass:"icon",attrs:{"src":_vm.images.miao_coin,"alt":""}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coin),expression:"coin"}],attrs:{"type":"number","min":"1","placeholder":_vm.$t('task.amount')},domProps:{"value":(_vm.coin)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.coin=$event.target.value},function($event){return _vm.onCoinInput($event.target.value)}]}}),_c('i',{staticClass:"check iconfont icon-circle-check",attrs:{"disabled":_vm.coin <= 0},on:{"click":_vm.onCheck}})]:(_vm.comment.reward_info)?[_c('div',{staticClass:"reward"},[_c('img',{staticClass:"icon",attrs:{"src":_vm.images.miao_coin,"alt":""}}),_vm._v(" "+_vm._s(_vm.comment.reward_info.number > 0 ? "+" : "")+" "+_vm._s(Math.floor(_vm.comment.reward_info.number))+" MIAO COIN ")]),_c('i',{directives:[{name:"visible",rawName:"v-visible",value:(
              _vm.comment.reward_info &&
                _vm.comment.reward_info.status !== 'sent' &&
                ((_vm.allow('reward', 'update') && _vm.task.user_id === _vm.user.id) ||
                  _vm.allow('reward', 'other_update_reward'))
            ),expression:"\n              comment.reward_info &&\n                comment.reward_info.status !== 'sent' &&\n                ((allow('reward', 'update') && task.user_id === user.id) ||\n                  allow('reward', 'other_update_reward'))\n            "}],staticClass:"edit iconfont icon-bianji",on:{"click":_vm.onEditReward}}),_c('i',{directives:[{name:"visible",rawName:"v-visible",value:(
              _vm.comment.reward_info &&
                _vm.comment.reward_info.status !== 'sent' &&
                ((_vm.allow('reward', 'update') && _vm.task.user_id === _vm.user.id) ||
                  _vm.allow('reward', 'other_update_reward'))
            ),expression:"\n              comment.reward_info &&\n                comment.reward_info.status !== 'sent' &&\n                ((allow('reward', 'update') && task.user_id === user.id) ||\n                  allow('reward', 'other_update_reward'))\n            "}],staticClass:"edit iconfont icon-close-o",on:{"click":_vm.onDeleteReward}})]:_vm._e()],2):_vm._e()])]),_c('v-task-reply-list',{key:_vm.key + 'list',attrs:{"taskId":_vm.taskId,"task":_vm.task,"topId":_vm.comment.id,"comment":_vm.comment},on:{"change":function($event){return _vm.$emit('change')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }